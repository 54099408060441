<template>
    <div style="text-align: left">
      <article>
        <section>
          <div class="container mt-5">
            <div class="mb-3">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/menu-categories' }">Menu Categories</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: `/menu-category/${$route.params.CategoryID}/${$route.params.menuCategoryName}/menu-items` }">{{ $route.params.menuCategoryName }} - Products</el-breadcrumb-item>
                <el-breadcrumb-item>Edit Menu Item</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
            <h1
              class="mb-4"
              style="font-size: 1.1em; font-weight: 600;"
            >Edit Menu Item</h1>
            <div v-loading="isLoading">
              <el-form
                :model="itemForm"
                :rules="productRules"
                ref="itemForm"
                class="demo-itemForm2"
              >
              <div class="row">
                  <div class="col-md-6">
                    <label class="mt-3 mb-2">Item Name</label>
                    <el-form-item prop="ItemName">
                      <el-input
                        v-model="itemForm.ItemName"
                        placeholder="Item Name"
                        :disabled="isUploadingFile"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-md-6">
                    <label class="mt-3 mb-2">Item Price</label>
                    <el-form-item prop="Price">
                      <el-input
                        v-model="itemForm.Price"
                        placeholder="UGX 20000"
                        :disabled="isUploadingFile"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <label class="mt-3 mb-2">Number of Products</label>
                    <el-form-item prop="quantityAvailable">
                      <el-input
                        v-model="itemForm.quantityAvailable"
                        placeholder="100"
                        :disabled="isUploadingFile"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
  
                <div class="row">
                  <div class="col-md-12">
                    <label class="mt-3 mb-2">Menu Item Image</label><br />
                    <el-form-item>
                      <el-upload
                        class="upload-demo"
                        drag
                        action="''"
                        :on-change="handlePreview"
                        :on-remove="handleRemove"
                        :file-list="fileLists"
                        :auto-upload="false"
                        multiple
                      >
                        <div v-if="!isUploadingFile">
                          <i class="el-icon-upload"></i>
                          <div class="el-upload__text">
                            Drop file here or <em>click to upload</em>
                          </div>
                        </div>
                        <div v-else>
                          <el-progress
                            v-if="imageUploadingProgress != 100"
                            type="circle"
                            :color="progressColors"
                            :percentage="imageUploadingProgress"
                            :width="80"
                            :stroke-width="4"
                          ></el-progress>
                          <el-progress
                            v-else
                            type="circle"
                            :percentage="100"
                            status="success"
                            :width="80"
                            :stroke-width="4"
                          ></el-progress>
                          <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                            {{
                            imageUploadingProgress == 100
                              ? "Uploaded"
                              : "Uploading..."
                          }}
                          </div>
                        </div>
                        <!-- <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div> -->
                      </el-upload>
                    </el-form-item>
                  </div>
                </div>
  
                <div class="row">
                  <div class="col-md-12">
                    <label class="mt-3 mb-2">Menu Item Description</label><br />
                    <el-form-item prop="Description">
                      <el-input
                        type="textarea"
                        :rows="2"
                        v-model="itemForm.Description"
                        placeholder="Brief Description"
                        :disabled="isUploadingFile"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
  
                <hr />
                <el-form-item class="text-center">
                  <div
                    v-if="isUploadingFile"
                    class="d-flex justify-content-center"
                  >
                    <ScaleOut />
                  </div>
                  <el-button
                    type="primary"
                    v-else
                    @click="editProduct('itemForm')"
                  >Edit Menu Item</el-button>
                </el-form-item>
              </el-form>
  
              <div class="text-center">
                <div class="text-center">
                  <small>© 2022, All rights reserved.</small>
                </div>
                <div class="text-center">
                  <!--<small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                </div>
              </div>
            </div>
          </div>
        </section>
      </article>
    </div>
  </template>
  
  
  <script>
  import ScaleOut from "@/components/scale-out-component.vue";
  export default {
    components: {
      ScaleOut
    },
  
    data() {
      return {
        selectedFile: null,
        menu_item: {},
        isUploadingFile: false,
        isLoading: false,
        value: "",
        loading: false,
        loadingError: false,
        imageUploadingProgress: 0,
        progressColors: [
          { color: "#f56c6c", percentage: 25 },
          { color: "#e6a23c", percentage: 50 },
          { color: "#1989fa", percentage: 75 },
          { color: "#6f7ad3", percentage: 100 },
        ],
        itemForm: {
          ItemName: "",
          Price: "",
          quantityAvailable: "",
          Description: "",
        },
        fileLists: [],
  
        productRules: {
          ItemName: [
            {
              required: true,
              message: "Item Name is required",
              trigger: "blur",
            },
          ],
          Price: [
            {
              required: true,
              message: "Item Price is required",
              trigger: "blur",
            },
          ],
          quantityAvailable: [
            {
              required: true,
              message: "Number of Items is required",
              trigger: "blur",
            },
          ],
          Description: [
            {
              required: true,
              message: "Brief Description is required",
              trigger: "blur",
            },
          ],
        },
        disabled: false,
      };
    },
  
    mounted() {
      this.getMenuItemById();
    },
  
    methods: {
      handlePreview(file) {
        this.fileLists = [file];
        this.selectedFile = file.raw;
      },
  
      handleRemove() {
        this.selectedFile = null;
      },
  
      async getMenuItemById() {
        this.isLoading = true;
        this.isLoadingError = false;
        try {
          //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
          let request = await this.$http.get(
            `menu-items/only/${this.$route.params.MenuItemID}`
          );
          if (request.data.success && request.data.message === "ITEM_FETCHED_SUCCESSFULLY") {
            this.menu_item = request.data.menu_item;
            this.itemForm.ItemName = this.menu_item.ItemName;
            this.itemForm.Price = this.menu_item.Price;
            this.itemForm.quantityAvailable = this.menu_item.quantityAvailable;
            this.itemForm.Description = this.menu_item.Description;
            this.selectedFile = this.menu_item.ItemImage;
            this.fileLists = [
              {
                name: "Item_cover_image",
                url: `${this.menu_item.ItemImage}`,
              },
            ];
          } else throw "UNEXPECTED_RESPONSE_RECEIVED";
        } catch (error) {
          this.isLoadingError = true;
          //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
          return this.$notify({
            title: "Operation Failed",
            message: "Unable to fetch menu_item now, please try again",
            type: "error",
          });
        } finally {
          this.isLoading = false;
        }
      },
  
      async editProduct(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            this.isUploadingFile = true;
            if (this.selectedFile == null) {
              this.$notify({
                title: "Empty",
                message: "Product Cover photo is empty",
                type: "warning",
              });
            }
  
            try {
              this.isUploadingFile = true;
              const formData = new FormData();
              formData.append("ItemName", this.itemForm.ItemName);
              formData.append("Price", this.itemForm.Price);
              formData.append("quantityAvailable", this.itemForm.quantityAvailable);
              formData.append("Description", this.itemForm.Description );
              formData.append("ItemImage", this.selectedFile);
  
              let request = await this.$http.patch(
                `menu-items/${this.$route.params.MenuItemID}`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                  onUploadProgress: (progressEvent) => {
                    this.imageUploadingProgress = +(
                      (progressEvent.loaded / progressEvent.total) *
                      100
                    ).toFixed(0);
                  },
                }
              );
              if (
                request.data.success &&
                request.data.message == "ITEM_UPDATED_SUCCESSFULLY"
              ) {
                this.$router.push({
                  path: `/menu-category/${this.$route.params.CategoryID}/${this.$route.params.menuCategoryName}/menu-items`,
                });
                return this.$notify({
                  title: "Success",
                  message: "Menu Item Updated Successfully",
                  type: "success",
                });
              } else throw "UNEXPECTED_RESPONSE";
            } catch (error) {
              if (error.message == "NetworkError") {
                this.isUploadingFile = false;
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred please try again",
                  type: "warning",
                });
              }
              this.isUploadingFile = false;
              return this.$notify({
                title: "Upload Failed",
                message: "Unable to Upload profile picture now, Please try again",
                type: "error",
              });
            } finally {
              this.isUploadingFile = false;
            }
          } else {
            return false;
          }
        });
      },
    },
  };
  </script>
  
  <style scoped>
  .addBtn {
    background-color: green;
    color: white;
  }
  input[type="text"],
  select,
  textarea {
    width: 100%;
    padding: 10px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    background-color: #ffffff;
    font-size: 0.9em;
  }
  
  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .addbtn {
      width: 100%;
      margin-top: 10px;
    }
  }
  
  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .addbtn {
      width: 100%;
      margin-top: 10px;
    }
  }
  </style>